import React from "react";
import { useGlobalState } from "../GlobalState";
import { calculateTotalSalary } from "../util/wageCalculator";
import styled from "styled-components";
const Row = styled.tr`
  border: 1px solid white;
  border: none;
`;

const Cell = styled.td`
  border: none;
  padding: 0.1rem;
  border-bottom: 1px solid #414;
`;
const RightAlignedCell = styled(Cell)`
  text-align: right;
`;

const LeftAlignedCell = styled(Cell)`
  text-align: left;
`;

const RightAlignedHeading = styled.th`
  text-align: right;
`;

const LeftAlignedHeading = styled.th`
  text-align: left;
`;
const Table = styled.table`
  border-collapse: collapse;
`;

const stats: Array<{
  label: string;
  value: (participants: number, averageSalary: number) => string;
}> = [
  {
    label: "Second",
    value: (participants: number, averageSalary: number) =>
      calculateTotalSalary(participants, averageSalary)
        .perSecond()
        .toFixed(2)
  },
  {
    label: "Minute",
    value: (participants: number, averageSalary: number) =>
      calculateTotalSalary(participants, averageSalary)
        .perMinute()
        .toFixed(2)
  },
  {
    label: "Hour",
    value: (participants: number, averageSalary: number) =>
      calculateTotalSalary(participants, averageSalary)
        .perHour()
        .toFixed(2)
  },
  {
    label: "Day",
    value: (participants: number, averageSalary: number) =>
      calculateTotalSalary(participants, averageSalary)
        .perDay()
        .toFixed(2)
  },
  {
    label: "Week",
    value: (participants: number, averageSalary: number) =>
      calculateTotalSalary(participants, averageSalary)
        .perWeek()
        .toFixed(2)
  },
  {
    label: "Month",
    value: (participants: number, averageSalary: number) =>
      calculateTotalSalary(participants, averageSalary)
        .perMonth()
        .toFixed(2)
  },
  {
    label: "Year",
    value: (participants: number, averageSalary: number) =>
      calculateTotalSalary(participants, averageSalary)
        .perYear()
        .toFixed(2)
  }
];

export const MeetingStatsDisplay = () => {
  const { state } = useGlobalState();
  return (
    <Table>
      <thead>
        <Row>
          <RightAlignedHeading>Time:</RightAlignedHeading>
          <LeftAlignedHeading>Cost</LeftAlignedHeading>
        </Row>
      </thead>
      <tbody>
        {stats.map(stat => (
          <StatsRow
            text={stat.label}
            value={stat.value(state.participants, state.averageSalary)}
          />
        ))}
      </tbody>
    </Table>
  );
};

const StatsRow = ({ text, value }: { text: string; value: string }) => (
  <Row>
    <LeftAlignedCell>{text}:</LeftAlignedCell>
    <RightAlignedCell>${value}</RightAlignedCell>
  </Row>
);
